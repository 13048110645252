import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { headerData } from '../Components/main-table/main-table.component';
import { iBase64File } from '../Interfaces/Utils/iBase64File';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';
import { iTableExcelBulk } from '../Interfaces/Utils/iTableExcelBulk';

export class AddComponentsBulkPopupClass {
  componentArray: iComponent[] = [];

  filesArray: iBase64File[] = [];

  tableInfo: iTableExcelBulk[] = [];

  headerData: headerData = [
    {
      variant: 'standard',
      display: 'Componentes detectados',
      key: 'componentName',
      alignment: 'start',
      width: '250px',
      className: 'truncateText maxWidth-250',
      ifNotContent: (row: any) => {
        return '-';
      },
    },
    {
      variant: 'standard',
      display: 'Características',
      key: 'ccasNumber',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
      ifNotContent: (row: any) => {
        return '-';
      },
    },
  ];

  viewId: number = 0;

  selectedRows: iTableExcelBulk[] = [];

  saveButtonOptions: iButtonOptions = {
    class: 'primary',
    icon: {
      icon: true,
      image: IconsHandlerService.getTickIcon(),
    },
    text: 'Cargar todo',
  };
}
interface iComponent {
  component: {
    name: string;
    categoryName: string | null;
    categoryColor: string;
    whoPreparesInitialism: string | null;
    preparationDate: string | null;
    whoReviewsInitialism: string | null;
    reviewDate: string | null;
    whoApprovesInitialism: string | null;
    approvalDate: string | null;
  };
  sections: Section[];
}

interface Section {
  name: string;
  type: string;
  children: (SubSection | CCA)[];
}

interface SubSection {
  type: 'subsection';
  name: string;
  children: CCA[];
}

interface CCA {
  type: 'cca';
  name: string;
  isCC: boolean;
  sequence?: number | null;
  verificationType?: string | null;
  acceptanceMethod?: number | null;
  samplingPlan?: string | null;
  testProcedure?: string | null;
  acceptanceRequirements?: string | null;
  documentationCA?: string | null;
  obsLab?: string | null;
  observations?: string | null;
}
