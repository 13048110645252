<div class="popupGeneralContainer">
  <div class="header">
    Añadir componentes .xlxs
    <mat-icon (click)="closePopup(false)">close</mat-icon>

  </div>
  <div class="body">
    @if (extraClass.componentArray.length == 0) {
    <add-file style="width: 100%;"
      [acceptedFileTypesArray]="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
      [maxFileSizeMB]="10" [filesArray]="extraClass.filesArray" (emitFiles)="getFile($event)" />
    } @else {
    <main-table [selectMultiple]="true" [dataSource]="extraClass.tableInfo" [headerData]="extraClass.headerData" (changeSelectedRows)="getSelectedRows($event)" />
    }
  </div>

  <div class="footer">
    @if(extraClass.componentArray.length > 0){
    <br>
    <custom-button [options]="extraClass.saveButtonOptions" (click)="onClickSaveButton()" />
    }
  </div>
</div>