import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddComponentsBulkPopupClass } from '../../Shared/Class/AddComponentsBulkPopupClass';
import { ExcelService } from '../../Services/Utils/Excel.service';
import { StaticDataHandlerService } from '../../Services/Utils/StaticDataHandler.service';
import { iTableExcelBulk } from '../../Shared/Interfaces/Utils/iTableExcelBulk';
import { SelectedDataTableBridgeService } from '../../Services/Utils/SelectedDataTableBridge.service';
import { iDate } from '@quasar-dynamics/ngx-qd-design-system';

@Component({
  selector: 'Add-Components-Bulk-Popup',
  templateUrl: './Add-Components-Bulk-Popup.component.html',
  styleUrls: ['./Add-Components-Bulk-Popup.component.scss'],
})
export class AddComponentsBulkPopupComponent implements OnInit {
  extraClass: AddComponentsBulkPopupClass = new AddComponentsBulkPopupClass();

  constructor(
    public dialogRef: MatDialogRef<AddComponentsBulkPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private excelSE: ExcelService,
    private selectedBrigdeSE: SelectedDataTableBridgeService
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  async getFile(event: any) {
    const file = event[0].file;

    const data: iExcelBodyToImport[] = await this.excelSE.importExcelComponents(
      file
    );
    this.manageJsonFromExcel(data);
  }

  manageJsonFromExcel(data: iExcelBodyToImport[]) {
    const componentArray: iComponent[] = this.generateJsonFromExcelArrays(data);
    // this.closePopup(componentArray);
    this.extraClass.componentArray = componentArray;
    this.generateInfoForTable(componentArray);
  }

  generateJsonFromExcelArrays(data: iExcelBodyToImport[]): iComponent[] {
    const componentMap = new Map<string, iComponent>();

    data.forEach((element) => {
      const componentName = element['Nombre componente'];
      const sectionName = element['Seccion'];
      const subSectionName = element['Subseccion'];
      const ccaName = element['Nombre CCA'];
      const fechaPreparado = element['Fecha preparado'];
      const fechaRevisado = element['Fecha revisado'];
      const fechaAprobado = element['Fecha aprobado'];

      // Initialize component if not present
      if (!componentMap.has(componentName)) {
        componentMap.set(componentName, {
          component: {
            name: componentName,
            categoryName: element['Categoría del componente'] ?? null,
            whoPreparesInitialism: element['Sigla preparado'] ?? null,
            categoryColor: this.getRandomCategoryColor(),
            preparationDate: fechaPreparado
              ? element['Fecha preparado']!
              : null,
            whoReviewsInitialism: element['Sigla revisado'] ?? null,
            reviewDate: fechaRevisado ? element['Fecha revisado']! : null,
            whoApprovesInitialism: element['Sigla aprobado'] ?? null,
            approvalDate: fechaAprobado ? element['Fecha aprobado']! : null,
          },
          sections: [],
        });
      }

      const component = componentMap.get(componentName)!;

      // Find or create section
      let section = component.sections.find(
        (section) => section.name === sectionName
      );
      if (!section) {
        section = {
          name: sectionName,
          type: 'section',
          children: [],
        };
        component.sections.push(section);
      }

      if (subSectionName) {
        // Find or create subsection
        let subSection = section.children.find(
          (subsection) =>
            subsection.type === 'subsection' &&
            subsection.name === subSectionName
        ) as SubSection | undefined;
        if (!subSection) {
          subSection = {
            type: 'subsection',
            name: subSectionName,
            children: [],
          };
          section.children.push(subSection);
        }
        // Add CCA to subsection
        subSection.children.push({
          type: 'cca',
          name: ccaName ?? '',
          isCC: element['CC'] === 1,
          acceptanceMethod: element['Método aceptación'] ?? null,
          verificationType: element['Tipo verificación'] ?? null,
          samplingPlan: element['Muestreo'] ?? null,
          testProcedure: element['Procedimiento ensayo'] ?? null,
          acceptanceRequirements: element['Criterio aceptación'] ?? null,
          documentationCA: element['Documentación CA'] ?? null,
          obsLab: element['Obs/Lab'] ?? null,
          observations: element['Comentarios'] ?? null,
        });
      } else {
        // Add CCA directly to section
        section.children.push({
          type: 'cca',
          name: ccaName ?? '',
          isCC: element['CC'] === 1,
          acceptanceMethod: element['Método aceptación'] ?? null,
          verificationType: element['Tipo verificación'] ?? null,
          samplingPlan: element['Muestreo'] ?? null,
          testProcedure: element['Procedimiento ensayo'] ?? null,
          acceptanceRequirements: element['Criterio aceptación'] ?? null,
          documentationCA: element['Documentación CA'] ?? null,
          obsLab: element['Obs/Lab'] ?? null,
          observations: element['Comentarios'] ?? null,
        });
      }
    });

    return Array.from(componentMap.values());
  }

  generateInfoForTable(componentArray: iComponent[]) {
    componentArray.forEach((component, index) => {
      const tableInfoLine: iTableExcelBulk = {
        ccasNumber: 0,
        componentName: component.component.name,
        id: index,
      };
      component.sections.forEach((section) => {
        section.children.forEach((sectionChild) => {
          if (sectionChild.type === 'cca') {
            tableInfoLine.ccasNumber++;
          } else {
            sectionChild.children.forEach((subsectionChild) => {
              tableInfoLine.ccasNumber++;
            });
          }
        });
      });
      this.extraClass.tableInfo.push(tableInfoLine);
    });
  }

  getRandomCategoryColor(): string {
    const colors = StaticDataHandlerService.getColorsForCategories();
    return colors[Math.floor(Math.random() * colors.length)];
  }

  getSelectedRows(rows: iTableExcelBulk[]) {
    if (rows.length <= 0) {
      this.extraClass.selectedRows = [];
      this.extraClass.saveButtonOptions.text = 'Cargar todo';
    } else {
      this.extraClass.selectedRows = rows;
      this.extraClass.saveButtonOptions.text = 'Cargar seleccionados';
    }
  }

  onClickSaveButton() {
    const selectedRows = this.extraClass.selectedRows;
    if (selectedRows.length <= 0) {
      this.closePopup(this.extraClass.componentArray);
    } else {
      const selectedComponents = selectedRows.map(
        (row) => this.extraClass.componentArray[row.id]
      );
      this.selectedBrigdeSE.clearSelectedData();
      this.closePopup(selectedComponents);
    }
  }
}
export interface iExcelBodyToImport {
  'Nombre componente': string;
  'Categoría del componente'?: string;
  'Sigla preparado'?: string;
  'Fecha preparado'?: string;
  'Sigla revisado'?: string;
  'Fecha revisado'?: string;
  'Sigla aprobado'?: string;
  'Fecha aprobado'?: string;
  Seccion: string;
  Subseccion?: string;
  'Nombre CCA'?: string;
  CC?: number;
  'Método aceptación'?: number;
  'Tipo verificación'?: string;
  Muestreo?: string;
  'Procedimiento ensayo'?: string;
  'Criterio aceptación'?: string;
  'Documentación CA'?: string;
  'Obs/Lab'?: string;
  Comentarios?: string;
}

interface iComponent {
  component: {
    name: string;
    categoryName: string | null;
    categoryColor: string;
    whoPreparesInitialism: string | null;
    preparationDate: string | null;
    whoReviewsInitialism: string | null;
    reviewDate: string | null;
    whoApprovesInitialism: string | null;
    approvalDate: string | null;
  };
  sections: Section[];
}

export interface Section {
  name: string;
  type: string;
  children: (SubSection | CCA)[];
}

interface SubSection {
  type: 'subsection';
  name: string;
  children: CCA[];
}

interface CCA {
  type: 'cca';
  name: string;
  isCC: boolean;
  sequence?: number | null;
  verificationType?: string | null;
  acceptanceMethod?: number | null;
  samplingPlan?: string | null;
  quantityAssayed?: number | null;
  testProcedure?: string | null;
  acceptanceRequirements?: string | null;
  documentationCA?: string | null;
  obsLab?: string | null;
  observations?: string | null;
}
